import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "project-setup"
    }}>{`Project setup`}</h1>
    <p>{`At Tia we're using`}</p>
    <ul>
      <li parentName="ul">{`React with TypeScript`}</li>
      <li parentName="ul">{`CRA-based configuration`}</li>
      <li parentName="ul">{`React 17`}</li>
      <li parentName="ul">{`React Router for routing`}</li>
      <li parentName="ul">{`react-hook-form for form management`}</li>
      <li parentName="ul">{`design system based on ThemeUI`}</li>
      <li parentName="ul">{`React Query for API integration`}</li>
      <li parentName="ul">{`Storybook for UI components`}</li>
    </ul>
    <p>{`The stack gives us a good balance between developer experience and quality. The end goal is that you're able to slap things together and they magically look great and work right.`}</p>
    <p>{`To try this out and align on the core approach, we're going to build a smol app using the same stack. Start by cloning the repo: `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train"
      }}>{`https://github.com/AskTia/tia-react-train`}</a></p>
    <p>{`Each exercise will have a coresponding solution branch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      